import { Slots } from '@collinsonx/utils';
import { GUEST_TYPES } from 'config/productDefaultConfig';
import useProduct from 'hooks/useProduct';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getTaxableGuestsCount } from 'utils/product';

export interface Booking {
  [GUEST_TYPES.adults]: number;
  [GUEST_TYPES.children]: number;
  [GUEST_TYPES.infants]: number;
  [GUEST_TYPES.seniors]: number;
  amendmentCurrentAttendees: number;
  amendmentID?: string;
  arrival?: string;
  arrivalTime?: string;
  bookingId?: string;
  carrierCode?: string;
  currentPrice: number | undefined;
  departureDate?: Date | null;
  existing_booking_slot: string;
  flightNumber: string;
  flightTime?: string;
  guestsCount: number;
  pendingPayment: boolean;
  priceIsAmended: boolean;
  quantity: number;
  reference: string | undefined;
  selectedEntitlementsCount: number;
  selectedSlot?: Slots;
  slotConfigurationId?: string;
  taxableGuestsCount: number;
}

interface BookingContextProps {
  booking: Booking;
  setBooking: (booking: {}) => void;
}

const setBookingStorage = (booking: Booking) => {
  if (typeof window === 'undefined') return;

  sessionStorage.setItem('bookingdetail', JSON.stringify(booking));
};

const getBookingStorage = () => {
  if (typeof window === 'undefined') return {};

  const storageObject = JSON.parse(
    sessionStorage.getItem('bookingdetail') ?? '{}'
  );

  return {
    ...defaultPropertyValues,
    ...storageObject,
  };
};

const defaultPropertyValues = {
  departureDate: null,
  flightNumber: '',
  [GUEST_TYPES.adults]: 1,
  [GUEST_TYPES.children]: 0,
  [GUEST_TYPES.infants]: 0,
  [GUEST_TYPES.seniors]: 0,
  pendingPayment: false,
  priceIsAmended: false,
  quantity: 0,
  selectedEntitlementsCount: 0,
  taxableGuestsCount: 1,
};

const defaultContext = {
  booking: getBookingStorage(),
  setBooking: () => {},
};

export const BookingContext =
  createContext<BookingContextProps>(defaultContext);

const BookingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { guests } = useProduct();

  const setBookingInterceptor = (booking: Booking): Booking => {
    const {
      adults,
      children,
      departureDate,
      infants,
      selectedEntitlementsCount,
      seniors,
    } = booking;

    const selectedGuestsCount = {
      [GUEST_TYPES.adults]: adults,
      [GUEST_TYPES.children]: children,
      [GUEST_TYPES.infants]: infants,
      [GUEST_TYPES.seniors]: seniors,
    };

    const taxableGuestsCount = getTaxableGuestsCount(
      selectedGuestsCount,
      guests
    );
    const pendingPayment = taxableGuestsCount > selectedEntitlementsCount;
    const quantity = taxableGuestsCount - selectedEntitlementsCount;

    return {
      ...booking,
      pendingPayment,
      quantity,
      selectedEntitlementsCount: departureDate ? selectedEntitlementsCount : 0,
      taxableGuestsCount,
    };
  };

  const [bookingState, setBookingState] = useState<Booking>(
    setBookingInterceptor(getBookingStorage())
  );

  const setBooking = (value: {}) => {
    setBookingState((oldBookingState) =>
      setBookingInterceptor({
        ...oldBookingState,
        ...value,
      })
    );
  };

  useEffect(() => {
    setBookingStorage(bookingState);
  }, [bookingState]);

  const contextValue = useMemo(
    () => ({
      booking: bookingState,
      setBooking,
    }),
    [bookingState]
  );

  return (
    <BookingContext.Provider value={contextValue}>
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
