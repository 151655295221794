import { GuestsType } from 'types/mappedProductData';

import { DEFAULT_PARTNER_KEY } from './constants';

export enum GUEST_TYPES {
  adults = 'adults',
  children = 'children',
  infants = 'infants',
  seniors = 'seniors',
}

export const DEFAULT_MAX_BOOKING_PERIOD = 9999;
export const DEFAULT_MIN_BOOKING_PERIOD = 48;
export const DEFAULT_CANCELLATION_PERIOD = 48;
export const DEFAULT_MAX_PARTY_SIZE = 5;
export const DEFAULT_TIME_SLOT_PERIOD = 15;
export const DEFAULT_CURRENCY = 'GBP';
export const DEFAULT_PRICE = 6;
export const DEFAULT_VIEW_ONLY = false;
export const DEFAULT_LEGACY_PRODUCT = false;

export const DEFAULT_GUESTS_PARAMS = {
  [GUEST_TYPES.adults]: {
    age: '12+',
    isChargeable: true,
    isPartySizeImpacted: true,
    maxCountPerGuestType: 5,
    price: DEFAULT_PRICE,
  },
  [GUEST_TYPES.children]: {
    age: '2-11',
    isChargeable: true,
    isPartySizeImpacted: true,
    maxCountPerGuestType: 5,
    price: DEFAULT_PRICE,
  },
  [GUEST_TYPES.infants]: {
    age: '0-2',
    isChargeable: false,
    isPartySizeImpacted: false,
    maxCountPerGuestType: 5,
    price: 0,
  },
  [GUEST_TYPES.seniors]: {
    age: '65+',
    isChargeable: false,
    isPartySizeImpacted: true,
    maxCountPerGuestType: 5,
    price: DEFAULT_PRICE,
  },
};

export const DEFAULT_GUESTS: GuestsType = {
  [GUEST_TYPES.adults]: DEFAULT_GUESTS_PARAMS[GUEST_TYPES.adults],
  [GUEST_TYPES.children]: DEFAULT_GUESTS_PARAMS[GUEST_TYPES.children],
  [GUEST_TYPES.infants]: DEFAULT_GUESTS_PARAMS[GUEST_TYPES.infants],
};

export const FAQ_PP = 'https://memberhelp.prioritypass.com/en/support/home';
export const FAQ_LK = 'https://www.loungekey.com/en/contact-us';
export const FAQ_AMEX_PREBOOK_ENTITLEMENTS =
  'https://prioritypass.com/pre-book-entitlements';

export const DEFAULT_CONTEXT_PRODUCT_VALUE = {
  airportCode: '',
  airportName: '',
  bookingMaxPeriodAllowed: DEFAULT_MAX_BOOKING_PERIOD,
  bookingMinPeriodAllowed: DEFAULT_MIN_BOOKING_PERIOD,
  cancellationPeriod: DEFAULT_CANCELLATION_PERIOD,
  currency: DEFAULT_CURRENCY,
  guests: DEFAULT_GUESTS,
  imageUrl: '',
  legacyProduct: DEFAULT_LEGACY_PRODUCT,
  loungeCode: '',
  loungeID: '',
  loungeName: '',
  maxPartySize: DEFAULT_MAX_PARTY_SIZE,
  partnerID: '',
  partnerProductID: DEFAULT_PARTNER_KEY,
  productID: '',
  saleprice: DEFAULT_PRICE,
  stripeID: '',
  terminal: '',
  timeSlotPeriod: DEFAULT_TIME_SLOT_PERIOD,
  timezone: '',
  validPrebookPeriod: true,
  viewOnly: DEFAULT_VIEW_ONLY,
};
