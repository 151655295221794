import {
  Experience,
  Outlet,
  OutletIdType,
  PrimaryProductAccessType,
} from '@collinsonx/utils';
import { useLazyQuery } from '@collinsonx/utils/apollo';
import { getSearchExperiences } from '@collinsonx/utils/queries';
import {
  convertExperienceToProduct,
  convertOutletToProduct,
} from 'adapters/productAdapter';
import { apiAccountProviderMap } from 'config/constants';
import { FEATURE_FLAGS } from 'config/optimizely';
import { getFlight } from 'context/flightContext';
import { ProductContext } from 'context/ProductContext';
import dayjs from 'dayjs';
import getBookingOutlet from 'gql-queries/getBookingOutlet';
import usePayload from 'hooks/payload';
import { useContext, useEffect, useState } from 'react';
import { MappedProductData } from 'types/mappedProductData';
import { formatTimezone } from 'utils/DateFormatter';

import { useError } from './error';
import useFeatureFlag from './useFeatureFlag';
import useLocale from './useLocale';

const useProduct = () => {
  const [productParsed, setProductParsed] = useState<MappedProductData>();

  const { loungeCode, payload, userIsSignedIn } = usePayload();
  const { product, setProduct } = useContext(ProductContext);
  const { setError } = useError();
  const translation = useLocale();
  const flightData = getFlight();
  const useProductCatalogueExperiment = useFeatureFlag(
    FEATURE_FLAGS.PRODUCT_CATALOGUE_EXPERIMENT,
    {
      enabledByDefault: true,
    }
  );

  const { accountProvider } = payload || {};

  const nowTimeLocal = dayjs();

  const departureTimeLocal = formatTimezone(
    flightData?.departure?.dateTime?.local,
    product.timezone
  );

  const canPrebookInMinPeriod =
    departureTimeLocal &&
    nowTimeLocal.isBefore(
      dayjs(departureTimeLocal).subtract(
        product.bookingMinPeriodAllowed,
        'hour'
      )
    );

  const canPrebookingInMaxPeriod =
    departureTimeLocal &&
    dayjs(departureTimeLocal).isBefore(
      nowTimeLocal.add(product.bookingMaxPeriodAllowed, 'hour')
    );

  const validPrebookPeriod = canPrebookInMinPeriod && canPrebookingInMaxPeriod;
  const checkValidCancellationPeriod = (departureTime?: string) =>
    departureTime &&
    nowTimeLocal.isBefore(
      dayjs(departureTime).subtract(product.cancellationPeriod, 'hour')
    );

  const [fetchExperiences] = useLazyQuery<{
    searchExperiences: Experience[];
  }>(getSearchExperiences, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const experience = data?.searchExperiences.filter(
        (item) => item && item.loungeCode === loungeCode
      )[0];

      if (
        typeof experience === 'undefined' ||
        data?.searchExperiences.length === 0
      )
        return setError({
          error: null,
          isError: true,
          message: translation.generic.error.loungeNoFound,
        });

      setProductParsed(convertExperienceToProduct(experience));
    },
    onError: (error) => {
      setError({
        error,
        isError: true,
        message: translation.generic.error.loungeNoFound,
      });
    },
  });

  const [fetchOutlet] = useLazyQuery<{
    getOutlet: Outlet;
  }>(getBookingOutlet, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const outletProduct = data?.getOutlet?.products?.filter(
        (outlet) =>
          outlet?.accessType === PrimaryProductAccessType.ReservationFeeOnly
      )[0];

      if (!outletProduct)
        return setError({
          error: null,
          isError: true,
          message: translation.generic.error.loungeNoFound,
        });

      const productConfigParsed = convertOutletToProduct(
        data.getOutlet,
        outletProduct
      );

      setProductParsed(productConfigParsed);
    },
    onError: (error) => {
      setError({
        error,
        isError: true,
        message: translation.generic.error.loungeNoFound,
      });
    },
  });

  useEffect(() => {
    if (!apiAccountProviderMap[accountProvider!]) return;
    if (!userIsSignedIn) return;
    if (productParsed) return;

    if (useProductCatalogueExperiment) {
      fetchOutlet({
        variables: {
          accessType: 'RESERVATION_FEE_ONLY',
          additionalFilters: {
            accessType: PrimaryProductAccessType.ReservationFeeOnly,
            programme: accountProvider,
          },
          getOutletId: loungeCode,

          outletIdType: OutletIdType.LegacyCode,
          programme: 'PRIORITY_PASS',
        },
      });
    } else {
      fetchExperiences({
        variables: {
          searchFilter: {
            attribute: apiAccountProviderMap[accountProvider!],
            value: 'true',
          },
        },
      });
    }
  }, [accountProvider, userIsSignedIn, loungeCode]);

  useEffect(() => {
    if (!productParsed) return;

    setProduct(productParsed);
  }, [productParsed]);

  return {
    ...product,
    checkValidCancellationPeriod,
    loadingProduct: !productParsed,
    validPrebookPeriod,
  };
};

export default useProduct;
